import React from "react";
import { string, shape, arrayOf, number } from "prop-types";

import LocationChip from "../../components/LocationChip";
import SquareLinkButton from "../../components/SquareLinkButton";
import { toDate, toSlug, parseLocation, parseContractType } from "../../helpers";

import styles from "./styles.module.scss";

const MultiplePositions = ({ positions, title , contractType }) => {
  let limitedPositions = positions.slice(0, 3);


  return (
    <article className={styles.wrapper}>
      <h1 className={styles.title} tabIndex="0">
        {title}
      </h1>
      {limitedPositions.length && (
        <ul className={styles.list}>
          {limitedPositions.map((position) => (
            <li
              className={styles.line}
              key={`position_${position.id}`}
            >
              <div tabIndex="0">
                <h2 className={styles.positionTitle}>{position.title}</h2>
                <span className={styles.company}>{position.company}</span>
                <span className={styles.date}>
                  Publicada em {toDate(position.date, "DD/MM/YYYY")}
                </span>
              </div>
              <div className={styles.footer}>
                <div className={styles.footerLocation}>
                  <LocationChip
                    key={`location-${position.id}`}
                    location={position.location ?? parseContractType(contractType)}
                    inline
                    tabIndex="0"
                  />
                </div>
                <SquareLinkButton
                  key={`more-button-${position.id}`}
                  text="Ver detalhes"
                  url={`/vaga/${toSlug(position.title)}/${position.id}`}
                  size="small"
                />
              </div>
            </li>
          ))}
        </ul>
      )}
    </article>
  );
};

MultiplePositions.propTypes = {
  positions: arrayOf(
    shape({
      id: number.isRequired,
      title: string.isRequired,
      date: string.isRequired,
      location: string.isRequired,
      url: string.isRequired,
    }).isRequired
  ).isRequired,
  company: string.isRequired,
  title: string,
};

MultiplePositions.defaultProps = {
  title: null,
};

export default MultiplePositions;
